import './App.css';

import React from "react";
import Barracks from "./Components/Barracks.js";
import Briefing_Room from "./Components/Briefing_Room.js";
import War_Room from "./Components/War_Room.js";
import { Routes, Route } from 'react-router-dom';


function App() {

	return (
		
		<Routes>
			<Route path='/' element={<Barracks />} />
			<Route path='/Briefing_Room' element={<Briefing_Room />} />
			<Route path='/War_Room' element={<War_Room />} />
		</Routes>
  );
}

export default App;