


const EchoMap = [{
    level1: [12, 12, 12, 12, 12, 12, 12, 12, 11, 11, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 11, 11, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 11, 11, 12, 12, 12, 12, 12,
        12, 12, 12, 41, 41, 12, 12, 12, 11, 11, 52, 41, 12, 12, 12,
        12, 12, 12, 41, 41, 12, 12, 12, 11, 11, 52, 41, 12, 12, 12,
        12, 12, 12, 41, 52, 11, 11, 11, 11, 11, 52, 41, 12, 12, 12,
        12, 12, 12, 41, 52, 11, 11, 11, 11, 11, 52, 41, 12, 12, 12,
        12, 12, 12, 41, 52, 11, 11, 11, 11, 11, 52, 41, 12, 12, 12,
        12, 12, 12, 41, 52, 11, 11, 11, 11, 11, 52, 41, 12, 12, 12,
        12, 12, 12, 41, 52, 11, 11, 11, 11, 11, 52, 41, 12, 12, 12,
        12, 12, 12, 41, 52, 11, 11, 12, 12, 12, 41, 41, 12, 12, 12,
        12, 12, 12, 41, 52, 11, 11, 12, 12, 12, 41, 41, 12, 12, 12,
        12, 12, 12, 12, 12, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12],
    level2: [0, 0, 0, 0, 0, 58, 59, 60, 53, 55, 0, 0, 107, 0, 0,
        0, 0, 0, 0, 0, 78, 79, 80, 53, 55, 0, 0, 107, 0, 0,
        0, 0, 98, 89, 89, 89, 89, 89, 53, 55, 89, 89, 110, 0, 0,
        0, 0, 107, 0, 0, 58, 59, 60, 53, 55, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 78, 79, 80, 53, 55, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 43, 44, 44, 92, 55, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 53, 0, 0, 0, 55, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 53, 0, 0, 0, 55, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 53, 0, 0, 0, 55, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 53, 81, 64, 64, 65, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 53, 55, 132, 141, 132, 0, 0, 107, 0, 0,
        0, 0, 107, 0, 0, 53, 55, 132, 132, 141, 0, 0, 107, 0, 0,
        0, 0, 108, 89, 89, 53, 55, 89, 89, 89, 89, 89, 120, 0, 0,
        0, 0, 107, 0, 0, 53, 55, 132, 132, 141, 0, 0, 0, 0, 0,
        0, 0, 107, 0, 0, 53, 55, 132, 132, 141, 0, 0, 0, 0, 0],
    level3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 122, 122, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 122, 122, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
}];

export default EchoMap;