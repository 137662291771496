import React from "react";
import styled from 'styled-components';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EchoMap from './EchoMap.js';
import GolfMap from './GolfMap.js';
import HotelMap from './HotelMap.js';
import { Peer } from "peerjs";

//prepare data
const TopMargin = 120;
const Unit_size = 32;
const Jump = 32;
const Rows= 15;
const Cols = 15;

const L_Inf = { MOV: 2, HA: 3, SA: 5, HD: 3, SD: 2, Special: "Entrenchment", IMG: "L_Inf"}; 
const H_Inf = { MOV: 2, HA: 5, SA: 4, HD: 3, SD: 2, Special: "Entrenchment", IMG: "H_Inf"}; 
const IFV = { MOV: 6, HA: 3, SA: 5, HD: 2, SD: 4, Special: "Repair", IMG: "IFV"}; 
const MBT = { MOV: 4, HA: 5, SA: 4, HD: 2, SD: 4, Special: "Repair", IMG: "MBT"}; 
const Heli = { MOV: 6, HA: 5, SA: 5, HD: 1, SD: 1, Special: "Switch", IMG: "Heli" };
const None = { MOV: 0, HA: 0, SA: 0, HD: 0, SD: 0, Special: "Nothing", IMG: "None" };

const types = { l_inf: L_Inf, h_inf: H_Inf, ifv: IFV, mbt: MBT, heli: Heli, none: None };
const Types = { L_Inf: "Rifle Squad", H_Inf: "Weapons Squad", IFV: "Infantry Fighting Vehicle", MBT: "Main Battle Tank", Heli: "Helicopter", None: "No one" };

let Unit_Sel = 0;
let Act_Unit = 6;
let Lock = false;
let Lock1 = false;
let ally = "Blue";
let enemy = "Red";
let tabunit = "black";
let tabterrain = "black";
let Movements = [true, true, true, true];
let Active = [true, true, true, true];


const UP = "ArrowUp";
const DOWN = "ArrowDown";
const LEFT = "ArrowLeft";
const RIGHT = "ArrowRight";
const SPACE = "Space"; 
const ENTER = "Enter";
const END = "End";
			
let Action_Map = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],];

let Company = 0;
let Mapfact = 0;
let alphatype = 0;
let bravotype = 0;
let charlietype = 0;
let deltatype = 0;
let EneCompany = 0;
let echotype = 0;
let foxtrottype = 0;
let golftype = 0;
let hoteltype = 0;
let Unit_type = null;

let peer = new Peer();
let conn = null;


const delay = ms => new Promise(res => setTimeout(res, ms));

const War_Room = () => {

	//receive data from briefing room
	const platoon = useLocation();
	Mapfact = platoon.state.Mapfactor;
	let friends = platoon.state.Coin;
	let enemys = platoon.state.Enemy.Coin;
	let ID = platoon.state.ID + 1;
	let hostile = platoon.state.Enemy.ID + 1;

	ally = platoon.state.faction;
	Company = platoon.state.company;
	Mapfact = platoon.state.Mapfactor;
	alphatype = types[platoon.state.Alpha];
	bravotype = types[platoon.state.Bravo];
	charlietype = types[platoon.state.Charlie];
	deltatype = types[platoon.state.Delta];

	enemy = platoon.state.Enemy.faction;
	EneCompany = platoon.state.Enemy.company;
	echotype = types[platoon.state.Enemy.Alpha];
	foxtrottype = types[platoon.state.Enemy.Bravo];
	golftype = types[platoon.state.Enemy.Charlie];
	hoteltype = types[platoon.state.Enemy.Delta];

	const [Turn, setTurn] = useState(true);
	const [Status, setStatus] = useState('Your Turn');
	const [col, setcol] = useState("lightgreen");

	//prepare Units variable
	const [SelectorX, setSelectorX] = useState(0);
	const [SelectorY, setSelectorY] = useState(TopMargin + 0);
	const [AX, setAX] = useState(64 + (friends * 320));
	const [AY, setAY] = useState(TopMargin + 128);
	const [BX, setBX] = useState(64 + (friends * 320));
	const [BY, setBY] = useState(TopMargin + 192);
	const [CX, setCX] = useState(64 + (friends * 320));
	const [CY, setCY] = useState(TopMargin + 256);
	const [DX, setDX] = useState(64 + (friends * 320));
	const [DY, setDY] = useState(TopMargin + 320);
	const [EX, setEX] = useState(64 + (enemys * 320));
	const [EY, setEY] = useState(TopMargin + 128);
	const [FX, setFX] = useState(64 + (enemys * 320));
	const [FY, setFY] = useState(TopMargin + 192);
	const [GX, setGX] = useState(64 + (enemys * 320));
	const [GY, setGY] = useState(TopMargin + 256);
	const [HX, setHX] = useState(64 + (enemys * 320));
	const [HY, setHY] = useState(TopMargin + 320);

	const [AHP, setAHP] = useState(8);
	const [BHP, setBHP] = useState(8);
	const [CHP, setCHP] = useState(8);
	const [DHP, setDHP] = useState(8);
	const [EHP, setEHP] = useState(8);
	const [FHP, setFHP] = useState(8);
	const [GHP, setGHP] = useState(8);
	const [HHP, setHHP] = useState(8);

	const [AD, setAD] = useState(0);
	const [BD, setBD] = useState(0);
	const [CD, setCD] = useState(0);
	const [DD, setDD] = useState(0);
	const [ED, setED] = useState(0);
	const [FD, setFD] = useState(0);
	const [GD, setGD] = useState(0);
	const [HoD, setHoD] = useState(0);

	const [SSA, setSSA] = useState(false);
	const [SB, setSB] = useState(false);
	const [SC, setSC] = useState(false);
	const [SSD, setSSD] = useState(false);
	const [SE, setSE] = useState(false);
	const [SF, setSF] = useState(false);
	const [SG, setSG] = useState(false);
	const [SH, setSH] = useState(false);

	/* -- for future Use --
	const [TypeU, setTypeU] = useState("None");
	const [TypeB, setTypeA] = useState("None");
	const [TypeC, setTypeB] = useState("None");
	const [Type, setTypeC] = useState("None");
	const [TypeD, setTypeD] = useState("None");
	*/
	const [HP, setHP] = useState(0);
	const [UniX, setUniX] = useState(0);
	const [UniY, setUniY] = useState(0);
	const [MOV, setMOV] = useState(0);
	const [SA, setSA] = useState(0);
	const [SD, setSD] = useState(0);
	const [HA, setHA] = useState(0);
	const [HD, setHD] = useState(0);
	const [Special, setSpe] = useState("Nothing");

	const [TypeU, setTypeU] = useState("None");
	const [TypeT, setTypeT] = useState("None");
	const [TX, setTX] = useState(0);
	const [TY, setTY] = useState(0);
	const [D, setD] = useState(0);
	const [M, setM] = useState(0);

	let Alpha1 = { HP: AHP, type: alphatype, X: AX, Y: AY, BD: AD, S: SSA, Act: "Idle" };
	let Bravo1 = { HP: BHP, type: bravotype, X: BX, Y: BY, BD: BD, S: SB, Act: "Idle" };
	let Charlie1 = { HP: CHP, type: charlietype, X: CX, Y: CY, BD: CD, S: SC, Act: "Idle" };
	let Delta1 = { HP: DHP, type: deltatype, X: DX, Y: DY, BD: DD, S: SSD, Act: "Idle"};
	let Echo2 = { HP: EHP, type: echotype, X: EX, Y: EY, BD: ED, S: SE, Act: "Idle"}; 
	let Foxtrot2 = { HP: FHP, type: foxtrottype, X: FX, Y: FY, BD: FD, S: SF, Act: "Idle"}; 
	let Golf2 = { HP: GHP, type: golftype, X: GX, Y: GY, BD: GD, S: SG, Act: "Idle"}; 
	let Hotel2 = { HP: HHP, type: hoteltype, X: HX, Y: HY, BD: HoD, S: SH, Act: "Idle" };

	//preapare units Images
	const [Alpha, setAlpha] = useState("/" + ally + "/" + ally + "_" + Alpha1.type.IMG +"_"+Alpha1.Act+".gif");
	const [Bravo, setBravo] = useState("/"+ally+"/"+ally+"_"+Bravo1.type.IMG+"_"+Bravo1.Act+".gif");
	const [Charlie, setCharlie] = useState("/"+ally+"/"+ally+"_"+Charlie1.type.IMG+"_"+Charlie1.Act+".gif");
	const [Delta, setDelta] = useState("/"+ally+"/"+ally+"_"+Delta1.type.IMG+"_"+Delta1.Act+".gif");
	const [Echo, setEcho] = useState("/"+enemy+"/"+enemy+"_"+Echo2.type.IMG+"_"+Echo2.Act+".gif");
	const [Foxtrot, setFoxtrot] = useState("/"+enemy+"/"+enemy+"_"+Foxtrot2.type.IMG+"_"+Foxtrot2.Act+".gif");
	const [Golf, setGolf] = useState("/"+enemy+"/"+enemy+"_"+Golf2.type.IMG+"_"+Golf2.Act+".gif");
	const [Hotel, setHotel] = useState("/" + enemy + "/" + enemy + "_" + Hotel2.type.IMG + "_" + Hotel2.Act + ".gif");
	const [BluFor, setBlu] = useState("Nato_Symbol/Units/" + Company + ".png");
	const [RedFor, setRed] = useState("Nato_Symbol/Enemy/" + EneCompany + ".png");
	const [Alpha1HP, setAlphaHP] = useState("HP/HP_" + Alpha1.HP + ".png");
	const [Bravo1HP, setBravoHP] = useState("HP/HP_" + Bravo1.HP + ".png");
	const [Charlie1HP, setCharlieHP] = useState("HP/HP_" + Charlie1.HP + ".png");
	const [Delta1HP, setDeltaHP] = useState("HP/HP_" + Delta1.HP + ".png");
	const [UnitHP, setUnitHP] = useState("HP/HP_" + HP + ".png");
	
	let Units = [Alpha1, Bravo1, Charlie1, Delta1];
	let Enem = [Echo2, Foxtrot2, Golf2, Hotel2];
	
	let Actions="Idle";
	let Dir = false;

	const [DirA, setDirA] = useState("scaleX(" + (1 + (friends * (-2)))+")");
	const [DirB, setDirB] = useState("scaleX(" + (1 + (friends * (-2)))+")");
	const [DirC, setDirC] = useState("scaleX(" + (1 + (friends * (-2)))+")");
	const [DirD, setDirD] = useState("scaleX(" + (1 + (friends * (-2)))+")");
	const [DirE, setDirE] = useState("scaleX(" + (1 + (enemys * (-2)))+")");
	const [DirF, setDirF] = useState("scaleX(" + (1 + (enemys * (-2)))+")");
	const [DirG, setDirG] = useState("scaleX(" + (1 + (enemys * (-2)))+")");
	const [DirH, setDirH] = useState("scaleX(" + (1 + (enemys * (-2)))+")");


	//retrive map data
	let Map = 0;

	switch (Mapfact) {
		case 0:
			Map = EchoMap;
			break;
		case 1:
			Map = GolfMap;
			break;
		case 2:
			Map = HotelMap;
			break;
	}

	let level1Map = Map[0].level1;
	let level2Map = Map[0].level2;
	let level3Map = Map[0].level3;

	//draw map
	useEffect(() => {

		switch (ally) {
			case 'Blue':
				tabunit='Darkblue';
				break;
			case 'Yellow':
				tabunit='Orange';
				break;
			case 'Red':
				tabunit='Darkred';
				break;
			case 'Green':
				tabunit='Darkgreen';
				break;
		}

		peer = new Peer(ID);

		if (friends == 1) {
			setTurn(false);
			setStatus('Enemy Turn');
			setcol("darkred");
			Movements = [false, false, false, false];
			Active = [false, false, false, false];
		}

		const canvas = document.getElementById('test-canvas');
		const ctx = canvas.getContext('2d');

		let tileAtlas = new Image();
		tileAtlas.src = 'Tileset.png';
		tileAtlas.onload = drawTerrain;

		let tileSize = 24
		let tileOutputSize = 4/3;
		let updatedTileSize = tileSize * tileOutputSize;

		let atlasCol = 10;
		let atlasRow = 151;

		let mapCols = 15;
		let mapRows = 15;

		let mapHeight = mapRows * tileSize;
		let mapWidth = mapCols * tileSize;

		

		let mapIndex = 0;
		let sourceX = 0;
		let sourceY = 0;

		function drawTerrain() {
			for (let col = 0; col < mapHeight; col += tileSize) {
				for (let row = 0; row < mapWidth; row += tileSize) {
					let tileVal1 = level1Map[mapIndex];
					let tileVal2 = level2Map[mapIndex];
					let tileVal3 = level3Map[mapIndex];
					if (tileVal1 != 0) {
						tileVal1 -= 1;
						sourceY = Math.floor(tileVal1 / atlasCol) * tileSize;
						sourceX = (tileVal1 % atlasCol) * tileSize;
						ctx.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal2 != 0) {
						tileVal2 -= 1;
						sourceY = (Math.floor(tileVal2 / atlasCol) * tileSize);
						sourceX = (tileVal2 % atlasCol) * tileSize;
						ctx.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal3 != 0) {
						tileVal3 -= 1;
						sourceY = Math.floor(tileVal3 / atlasCol) * tileSize;
						sourceX = (tileVal3 % atlasCol) * tileSize;
						ctx.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					mapIndex++;
				}
			}
		}
		for (let t in Units) {
			TerrainBonus(Units[t]);
		}
		for (let t in Enem) {
			TerrainBonus(Enem[t]);
		}
	}, []);

	//checker terrain/unit
	const [time, setTime] = useState(new Date());
	let Sel_Unit = { type: None, X: 0, Y: TopMargin, BD: 0, Act: "Idle" };
	useEffect(() => {
		const interval = setInterval(() => {
			setTime(new Date());
			ShowInfo(SelectorX, SelectorY);
		}, 100);

		return () => clearInterval(interval);
	}, [SelectorX, SelectorY, Alpha1,Bravo1,Charlie1,Delta1,Echo2,Foxtrot2,Golf2,Hotel2]);
	
	//prepare the action map for movement
	function PrepareMov(L, Mov) {
		if (L == true) {
			let mob = 0;
			if ((Mov.type === IFV || Mov.type === MBT) && Mov.BD === 1) {
				mob = 2;
			}
			prepareMap(Mov.type.MOV-mob);
		}
		else {
			cleanMap();
		}
	}

	//the chooser for the action to take
	function Action( SX, SY){
		if (Unit_Sel==0 && Act_Unit==6) {
			for ( let key in Units) {
				if ( Units[key].X == SX && Units[key].Y == SY) {
					if (Active[key]==false) {
						break;
					}
					Unit_Sel=Units[key];
					Act_Unit=key;
					let Rows = (SelectorY - TopMargin)/Unit_size;
					let Cols = SelectorX/Unit_size;
					for (let col = Rows-1; col <= 1+Rows; col ++) {
						for (let row = Cols-1; row <= 1+Cols; row ++) {
							if (row >=  0 && col >= 0 && row < 480 && col < 480) {
								Action_Map[col][row] = 1;
							}
						}
					}
				}
			}
		}
		else {
			if (SX===Unit_Sel.X && SY===Unit_Sel.Y) {
				let tac=Tactic(Unit_Sel);
				if (tac != 0) {
					Active[Act_Unit] = !Active[Act_Unit];
					if (Unit_Sel.S == true) {
						Movements[Act_Unit] = !Movements[Act_Unit];
					}
				}
			}
			for (let key in Enem) {
				if (Enem[key].X === SX && Enem[key].Y === SY
					&& (Unit_Sel.type.Special === "Switch" && Unit_Sel.S === true) === false
					&& (Enem[key].type.Special === "Switch" && Enem[key].S === true) === false) {
					let sol = 0;
					if (Enem[key].type === L_Inf || Enem[key].type === H_Inf) {
						sol = SoftAttack(Unit_Sel, Enem[key]);
					}
					else if (Enem[key].type === IFV || Enem[key].type === MBT || Enem[key].type === Heli) {
						sol = HardAttack(Unit_Sel, Enem[key]);
					}
					if (sol != 0) {
						Active[Act_Unit] = false;
					}
				}
			}
			cleanMap();
			Unit_Sel=0;
			Act_Unit=6;
		}
	}

	//tactic
	function Tactic(Uni) {
		let sol = 0;
		let flag = false;
		if (Uni.type.Special === "Entrenchment") {
			sol = Uni.BD;
			if (Uni.S == false && Uni.BD == 0) {
				sol = 1;
				flag = true;
			}
			if (Uni.S == true && Uni.BD == 1) {
				sol = 0;
				flag = false;
			}
			switch (true) {
				case Uni.X==Alpha1.X && Uni.Y==Alpha1.Y:
					setAD(sol);
					setSSA(flag);
					break;
				case Uni.X == Bravo1.X && Uni.Y == Bravo1.Y:
					setBD(sol);
					setSB(flag);
					break;
				case Uni.X == Charlie1.X && Uni.Y == Charlie1.Y:
					setCD(sol);
					setSC(flag);
					break;
				case Uni.X == Delta1.X && Uni.Y == Delta1.Y:
					setDD(sol);
					setSSD(flag);
					break;
				default:
					sol = 0;
					break;
			}
			TerrainBonus(Uni);
		}
		else if (Uni.type.Special === "Repair") {
			if (Uni.HP < 7) {
				sol = Uni.HP + 2;
				flag = true;
			}
			else {
				sol = 8;
				if (Uni.HP == 7) {
					flag = true;
				}
			}
			switch (true) {
				case Uni.X == Alpha1.X && Uni.Y == Alpha1.Y:
					setAHP(sol);
					setSSA(flag);
					setAlphaHP("HP/HP_" + sol + ".png");
					break;
				case Uni.X == Bravo1.X && Uni.Y == Bravo1.Y:
					setBHP(sol);
					setSB(flag);
					setBravoHP("HP/HP_" + sol + ".png");
					break;
				case Uni.X == Charlie1.X && Uni.Y == Charlie1.Y:
					setCHP(sol);
					setSC(flag);
					setCharlieHP("HP/HP_" + sol + ".png");
					break;
				case Uni.X == Delta1.X && Uni.Y == Delta1.Y:
					setDHP(sol);
					setSSD(flag);
					setDeltaHP("HP/HP_" + sol + ".png");
					break;
				default:
					sol = 0;
					break;
			}
		}
		else if (Uni.type.Special === "Switch") {
			flag = !Uni.S;
			sol = 1;
			switch (true) {
				case Uni.X == Alpha1.X && Uni.Y == Alpha1.Y:
					setSSA(sol);
					setSSA(flag);
					break;
				case Uni.X == Bravo1.X && Uni.Y == Bravo1.Y:
					setSB(sol);
					setSB(flag);
					break;
				case Uni.X == Charlie1.X && Uni.Y == Charlie1.Y:
					setSC(sol);
					setSC(flag);
					break;
				case Uni.X == Delta1.X && Uni.Y == Delta1.Y:
					setSSD(sol);
					setSSD(flag);
					break;
				default:
					sol = 0;
					break;
			}
		}
		return sol;
	}

	//prepare the map for movement/actions
	function prepareMap(M) {
		let Rows = (SelectorY - TopMargin) / Unit_size;
		let Cols = SelectorX / Unit_size;
		for (let col = Rows-M; col <= M+Rows; col ++) {
			for (let row = Cols-M; row <= M+Cols; row ++) {
				if (row >=  0 && col >= 0 && row < 15 && col < 15) {
					let Index1 = Math.abs((Rows - row) + (Cols - col));
					let Index2 = Math.abs((Rows + row) - (Cols + col));
					if (Index1 <= M && Index2 <= M &&
						(level1Map[(col * 15) + row] != 11 || (121 === level3Map[(col * 15) + row] || level3Map[(col * 15) + row] === 122))
						&& Unit_type != Heli) {
							Action_Map[col][row] = 1;
					}
					else if (Index1 <= M && Index2 <= M && Unit_type == Heli) {
						Action_Map[col][row] = 1;
					}
				}
			}
		}
	}

	//clean map for movement and actions
	function cleanMap(){
		for (let col = 0; col < Rows; col ++) {
			for (let row = 0; row < Cols; row ++) {
				let Val = Action_Map[col][row];
				if (Val==1) {
					 Action_Map[col][row] = 0;
				}
			}
		}
	}

	//attack against infantry
	function SoftAttack(attacker, defender) {
		let sol = 0;
		if (defender.HP > 0) {
			sol = defender.HP - (attacker.type.SA - defender.type.SD - defender.BD);
			if (sol < 0) {
				sol = 0;
			}
		}
		switch (defender) {
			case Echo2:
				if (sol == 0) {
					setEX(448 + (1 * 32) + (friends * 128));
					setEY(TopMargin + 448);
				}
				setEHP(sol);
				break;
			case Foxtrot2:
				if (sol == 0) {
					setFX(448 + (2 * 32) + (friends * 128));
					setFY(TopMargin + 448);
				}
				setFHP(sol);
				break;
			case Golf2:
				if (sol == 0) {
					setGX(448 + (3 * 32) + (friends * 128));
					setGY(TopMargin + 448);
				}
				setGHP(sol);
				break;
			case Hotel2:
				if (sol == 0) {
					setHX(448 + (4 * 32) + (friends * 128));
					setHY(TopMargin + 448);
				}
				setHHP(sol);
				break;
			default:
				sol = 0;
				break;
		}
		return sol;
	}

	//attack against vehicle
	function HardAttack(attacker, defender) {
		let sol = defender.HP;
		if (defender.HP > 0) {
			sol = defender.HP - (attacker.type.HA - defender.type.HD);
			if (sol < 0) {
				sol = 0;
			}
		}
		switch (defender) {
			case Echo2:
				if (sol == 0) {
					setEX(448 + (1 * 32) + (friends * 128));
					setEY(TopMargin + 448);
				}
				setEHP(sol);
				break;
			case Foxtrot2:
				if (sol == 0) {
					setFX(448 + (2 * 32) + (friends * 128));
					setFY(TopMargin + 448);
				}
				setFHP(sol);
				break;
			case Golf2:
				if (sol == 0) {
					setGX(448 + (3 * 32) + (friends * 128));
					setGY(TopMargin + 448);
				}
				setGHP(sol);
				break;
			case Hotel2:
				if (sol == 0) {
					setHX(448 + (4 * 32) + (friends * 128));
					setHY(TopMargin + 448);
				}
				setHHP(sol);
				break;
			default:
				sol = 0;
				break;
		}
		return sol;
	}

	//bonus by the terrain
	function TerrainBonus(Unit) {
		let Index = (Unit.X / 32) + (((Unit.Y - TopMargin) / 32) * 15);
		if (((6 < level2Map[Index] && level2Map[Index] < 11) ||
			(16 < level2Map[Index] && level2Map[Index] < 21) ||
			(26 < level2Map[Index] && level2Map[Index] < 31) ||
			(36 < level2Map[Index] && level2Map[Index] < 41) ||
			(46 < level2Map[Index] && level2Map[Index] < 51) ||
			(56 < level2Map[Index] && level2Map[Index] < 61) ||
			(66 < level2Map[Index] && level2Map[Index] < 71) ||
			(76 < level2Map[Index] && level2Map[Index] < 81) ||
			(130 < level2Map[Index] && level2Map[Index] < 134) ||
			(140 < level2Map[Index] && level2Map[Index] < 145) ||
			(150 < level2Map[Index] && level2Map[Index] < 154))
			&& Unit.BD == 0 && (Unit.type === L_Inf || Unit.type === H_Inf)) {
			switch (Unit) {
				case Alpha1:
					setAD(1);
					break;
				case Bravo1:
					setBD(1);
					break;
				case Charlie1:
					setCD(1);
					break;
				case Delta1:
					setDD(1);
					break;
			}
		}
		else {
			if (Unit.BD > 0) {
				switch (Unit) {
					case Alpha1:
						setAD(0);
						break;
					case Bravo1:
						setBD(0);
						break;
					case Charlie1:
						setCD(0);
						break;
					case Delta1:
						setDD(0);
						break;
				}
			}
		}
	}

	//the controller for the info tables
	function ShowInfo(sx, sy) {
		let Sel_Unit = {HP:0, type: None, X: 0, Y: TopMargin, BD: 0, Act: "Idle" };
		for (let k in Units) {
			if (Units[k].X == sx && Units[k].Y == sy) {
				Sel_Unit = Units[k];

				break;
			}
		}
		for (let k in Enem) {
			if (Enem[k].X == sx && Enem[k].Y == sy) {
				Sel_Unit = Enem[k];
				break;
			}
		}
		let Index = (sx / 32) + (((sy - TopMargin) / 32) * 15);
		setTypeT("Open Field");
		setD(0);
		setM(0);
		if ((46 < level2Map[Index] && level2Map[Index] < 51) ||
			(56 < level2Map[Index] && level2Map[Index] < 61) ||
			(66 < level2Map[Index] && level2Map[Index] < 71) ||
			(76 < level2Map[Index] && level2Map[Index] < 81)) {
			setTypeT("Forest");
			setD(1);
			if (Sel_Unit.type === IFV || Sel_Unit.type === MBT) {
				setM(2);
			}
		}
		else if ((6 < level2Map[Index] && level2Map[Index] < 11) ||
			(16 < level2Map[Index] && level2Map[Index] < 21) ||
			(26 < level2Map[Index] && level2Map[Index] < 31) ||
			(36 < level2Map[Index] && level2Map[Index] < 41)) {
			setTypeT("Mountain");
			setD(1);
			if (Sel_Unit.type === IFV || Sel_Unit.type === MBT) {
				setM(2);
			}
		}
		else if ((130 < level2Map[Index] && level2Map[Index] < 134) ||
			(140 < level2Map[Index] && level2Map[Index] < 145) ||
			(150 < level2Map[Index] && level2Map[Index] < 154)) {
			setTypeT("Urban");
			setD(1);
			if (Sel_Unit.type === IFV || Sel_Unit.type === MBT) {
				setM(2);
			}
		}
		setTX((sx) / 32);
		setTY((sy - TopMargin) / 32);
		setTypeU(Types[Sel_Unit.type.IMG]);
		setUniX((Sel_Unit.X) / 32);
		setUniY((Sel_Unit.Y - TopMargin) / 32);
		setSD(Sel_Unit.type.SD + "+" + Sel_Unit.BD);
		setHP(Sel_Unit.HP);
		setUnitHP("HP/HP_" + Sel_Unit.HP + ".png");
		setMOV(Sel_Unit.type.MOV + "-" + M);
		setSA(Sel_Unit.type.SA);
		setHA(Sel_Unit.type.HA);
		setHD(Sel_Unit.type.HD);
		setSpe(Sel_Unit.type.Special);
	}

	//controller
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {

		let NewSelectorY = SelectorY;
		let NewSelectorX = SelectorX;
		switch (event.code) {
			case DOWN:
				NewSelectorY = SelectorY + Jump;
				if (NewSelectorY > 448 + TopMargin) {
					NewSelectorY = 448 + TopMargin;
				}
				if (Lock == true) {
					for (let t in Units) {
						if (Units[t].X == SelectorX && Units[t].Y == NewSelectorY) {
							NewSelectorY = SelectorY;
							break;
						}
					}
					for (let t in Enem) {
						if (Enem[t].X == SelectorX && Enem[t].Y == NewSelectorY) {
							NewSelectorY = SelectorY;
							break;
						}
					}
				}
				Actions = "Down";
				Dir = false;
				break;
			case LEFT:
				NewSelectorX = SelectorX - Jump;
				if (NewSelectorX < 0) {
					NewSelectorX = 0;
				}
				if (Lock == true) {
					for (let t in Units) {
						if (Units[t].X == NewSelectorX && Units[t].Y == SelectorY) {
							NewSelectorX = SelectorX;
							break;
						}
					}
					for (let t in Enem) {
						if (Enem[t].X == NewSelectorX && Enem[t].Y == SelectorY) {
							NewSelectorX = SelectorX;
							break;
						}
					}
				}
				Actions = "Mov";
				Dir = false;
				break;
			case RIGHT:
				NewSelectorX = SelectorX + Jump;
				if (NewSelectorX > 448) {
					NewSelectorX = 448;
				}
				if (Lock == true) {
					for (let t in Units) {
						if (Units[t].X == NewSelectorX && Units[t].Y == SelectorY) {
							NewSelectorX = SelectorX;
							break;
						}
					}
					for (let t in Enem) {
						if (Enem[t].X == NewSelectorX && Enem[t].Y == SelectorY) {
							NewSelectorX = SelectorX;
							break;
						}
					}
				}
				Actions = "Mov";
				Dir = true;
				break;
			case UP:
				NewSelectorY = SelectorY - Jump;
				if (NewSelectorY < TopMargin) {
					NewSelectorY = TopMargin;
				}
				if (Lock == true) {
					for (let t in Units) {
						if (Units[t].X == SelectorX && Units[t].Y == NewSelectorY) {
							NewSelectorY = SelectorY;
							break;
						}
					}
					for (let t in Enem) {
						if (Enem[t].X == SelectorX && Enem[t].Y == NewSelectorY) {
							NewSelectorY = SelectorY;
							break;
						}
					}
				}
				Actions = "Up";
				Dir = false;
				break;
			case SPACE:
				for (let k in Units) {
					if (Status == 'Your Turn' && Lock1 == false && Units[k].X == SelectorX && Units[k].Y == SelectorY && Movements[k] == true
						&& (Units[k].S == true && (Units[k].type == L_Inf || Units[k].type == H_Inf)==true )==false) {
						Lock = !Lock;
						Unit_type = Units[k].type;
						PrepareMov(Lock, Units[k]);
						if (Lock == false) {
							Movements[k] = false;
							Unit_Sel = 0;
						}
						TerrainBonus(Units[k]);
					}
				}
				break;
			case ENTER:
				if (Lock == false && Status == 'Your Turn') {
					Lock1 = !Lock1;
					Action(SelectorX, SelectorY);
				}
				break;
			case END:
				ShowInfo(Sel_Unit, SelectorX, SelectorY);
				break;
			default:
				break;
		}
		let MapRow = (NewSelectorY - TopMargin) / Unit_size;
		let MapCol = NewSelectorX / Unit_size;
		let Value = Action_Map[MapRow][MapCol];
		if (Value == 0 && (Lock == true || Unit_Sel != 0)) {
			setSelectorX(SelectorX);
			setSelectorY(SelectorY);
		}
		else {
			setSelectorX(NewSelectorX);
			setSelectorY(NewSelectorY);
			if (Lock == true) {
				if (SelectorX == AX && SelectorY == AY) {
					setAX(NewSelectorX);
					setAY(NewSelectorY);
					Alpha1.Act = Actions;
					setAlpha("/" + ally + "/" + ally + "_" + Alpha1.type.IMG + "_" + Alpha1.Act + ".gif");
					if (Dir == true) {
						setDirA("scaleX(1)");
					}
					else {
						setDirA("scaleX(-1)");
					}
				}
				else if (SelectorX == BX && SelectorY == BY) {
					setBX(NewSelectorX);
					setBY(NewSelectorY);
					Bravo1.Act = Actions;
					setBravo("/" + ally + "/" + ally + "_" + Bravo1.type.IMG + "_" + Bravo1.Act + ".gif");
					if (Dir == true) {
						setDirB("scaleX(1)");
					}
					else {
						setDirB("scaleX(-1)");
					}
				}
				else if (SelectorX == CX && SelectorY == CY) {
					setCX(NewSelectorX);
					setCY(NewSelectorY);
					Charlie1.Act = Actions;
					setCharlie("/" + ally + "/" + ally + "_" + Charlie1.type.IMG + "_" + Charlie1.Act + ".gif");
					if (Dir == true) {
						setDirC("scaleX(1)");
					}
					else {
						setDirC("scaleX(-1)");
					}
				}
				else if (SelectorX == DX && SelectorY == DY) {
					setDX(NewSelectorX);
					setDY(NewSelectorY);
					Delta1.Act = Actions;
					setDelta("/" + ally + "/" + ally + "_" + Delta1.type.IMG + "_" + Delta1.Act + ".gif");
					if (Dir === true) {
						setDirD("scaleX(1)");
					}
					else {
						setDirD("scaleX(-1)");
					}
				}
			}
		}
		event.preventDefault();
	}

	//prepare your next turn
	function NextPhase() {
		let c = 0;
		for (let k in Movements) {
			if (Units[k].HP > 0) {
				Movements[k] = true;
			}
		}
		for (let key in Active) {
			if (Units[key].HP >0) {
				c = c + 1;
				Active[key] = true;
			}
		}
		if (c > 0) {
			setStatus('Your Turn');
			setcol("lightgreen");
		}
	}

	//endTurn
	const EndTurn = async () => {
		if (Status == 'Your Turn' && Lock == false && Lock1 == false) {
			let f = 0;
			setStatus('Sending Info ...');
			setcol("orange");
			send('calculating', false);
			await delay(750);
			send('echo', Alpha1);
			await delay(750);
			send('foxtrot', Bravo1);
			await delay(750);
			send('golf', Charlie1);
			await delay(750);
			send('hotel', Delta1);
			await delay(750);
			send('alpha', Echo2);
			await delay(750);
			send('bravo', Foxtrot2);
			await delay(750);
			send('charlie', Golf2);
			await delay(750);
			send('delta', Hotel2);
			await delay(750);
			for (let k in Enem) {
				if (Enem[k].HP > 0) {
					f = f + 1;
				}
			}
			if (f > 0) {
				setStatus('Enemy Turn');
				setcol("darkred");
				Movements = [false, false, false, false];
				Active = [false, false, false, false];
				send('turn', true);
			}
			else {
				setStatus('<- you WIN');
				setcol("gold");
				Movements = [false, false, false, false];
				Active = [false, false, false, false];
				send('lose', true);
			}
		}
	}

	//send info to enemy
	function send(U, Obj) {
		let tosend = {
			Name: U,
			object: Obj
		};
		conn = peer.connect(hostile);
		conn.on('open', function () {
			conn.send(tosend);
		});
	}

	peer.on("open", function (id) {
		console.log('my ID: ' + id);
	});

	peer.on('error', function (err) {
		console.log(err);
		alert('' + err);
	});

	//receive and update info
	peer.on("connection", function (conn) {
		conn.on("data", function (data) {
			switch (data.Name) {
				case 'alpha':
					setAHP(data.object.HP);
					setAX(data.object.X);
					setAY(data.object.Y);
					setAlphaHP("HP/HP_" + data.object.HP + ".png");
					break;
				case 'bravo':
					setBHP(data.object.HP);
					setBX(data.object.X);
					setBY(data.object.Y);
					setBravoHP("HP/HP_" + data.object.HP + ".png");
					break;
				case 'charlie':
					setCHP(data.object.HP);
					setCX(data.object.X);
					setCY(data.object.Y);
					setCharlieHP("HP/HP_" + data.object.HP + ".png");
					break;
				case 'delta':
					setDHP(data.object.HP);
					setDX(data.object.X);
					setDY(data.object.Y);
					setDeltaHP("HP/HP_" + data.object.HP + ".png");
					break;
				case 'echo':
					setEX(data.object.X);
					setEY(data.object.Y);
					setEHP(data.object.HP);
					setED(data.object.BD);
					setSE(data.object.S);
					break;
				case 'foxtrot':
					setFX(data.object.X);
					setFY(data.object.Y);
					setFHP(data.object.HP);
					setFD(data.object.BD);
					setSF(data.object.S);
					break;
				case 'golf':
					setGX(data.object.X);
					setGY(data.object.Y);
					setGHP(data.object.HP);
					setGD(data.object.BD);
					setSG(data.object.S);
					break;
				case 'hotel':
					setHX(data.object.X);
					setHY(data.object.Y);
					setHHP(data.object.HP);
					setHoD(data.object.BD);
					setSH(data.object.S);
					break;
				case 'calculating':
					setStatus('Receving new Info ...');
					setcol("orange");
					break;
				case 'turn':
					NextPhase();
					break;
				case 'lose':
					setStatus('<- you LOSE');
					setcol("red");
					break;
			}	
		});
	});

	//return to briefing room
	const navigate = useNavigate();
	const barracks = () => {
		if (Status == '<- you LOSE' || Status == '<- you WIN') {
			navigate("/Briefing_Room");
		}
	};


  return (
	  <Div tabIndex={0} onKeyDown={handleKeyDown} top={TopMargin}>
		  <Container height="30" col={col}>
			  <button onClick={barracks} top={TopMargin + 416} left={480}>Briefing Room</button>
			  <turn><strong>{Status}</strong></turn>
			  <button onClick={EndTurn} top={10} left={210}>End Turn</button>
		  </Container>
		  <Unit src={BluFor} height="50" weight="50" top={50} left={50 + (friends * 320)} />
		  <Unit src={RedFor} height="70" weight="70" top={40} left={50 + (enemys * 320)} />
		  <StrategicMap id="test-canvas" width="480" height="480" top={TopMargin} left="0" />
		<Unit id="Alpha" src={Alpha} Direct={DirA}
			  height={Unit_size} width={Unit_size} top={AY} left={AX} />
		<Unit id="Bravo" src={Bravo} Direct={DirB}
			height={Unit_size} width={Unit_size} top={BY} left={BX} />
		<Unit id="Charlie" src={Charlie} Direct={DirC}
			height={Unit_size} width={Unit_size} top={CY} left={CX} />
		<Unit id="Delta"  src={Delta} Direct={DirD}
			height={Unit_size} width={Unit_size} top={DY} left={DX} />
		<Unit id="Echo" src={Echo} Direct={DirE}
			height={Unit_size} width={Unit_size} top={EY} left={EX} />
		<Unit id="Foxtrot" src={Foxtrot} Direct={DirF}
			height={Unit_size} width={Unit_size} top={FY} left={FX} />
		<Unit id="Golf" src={Golf} Direct={DirG}
			height={Unit_size} width={Unit_size} top={GY} left={GX} />
		<Unit id="Hotel"  src={Hotel} Direct={DirH}
			height={Unit_size} width={Unit_size} top={HY} left={HX} />
		<Selector src="Select.gif" size={Unit_size} top={SelectorY} left={SelectorX} />
		  <InfoTab top={TopMargin + 160} left={480} width="300" tabcolor={tabterrain}>
			  <tr>
				  <th>Selected Unit:</th> <th>Values:</th>
			  </tr>
			  <tr>
				  <td>Type:</td> <td>{TypeU}</td>
			  </tr>
			  <tr>
				  <td>HP:</td> <td>{HP} - <img align="center" src={UnitHP} /></td>
			  </tr>
			  <tr>
				  <td>Mobility:</td> <td>{MOV}</td>
			  </tr>
			  <tr>
				  <td>Soft Attack:</td> <td>{SA}</td>
			  </tr>
			  <tr>
				  <td>Soft Defense:</td> <td>{SD}</td>
			  </tr>
			  <tr>
				  <td>Hard Attack:</td> <td>{HA}</td>
			  </tr>
			  <tr>
				  <td>Hard Defense:</td> <td>{HD}</td>
			  </tr>
			  <tr>
				  <td>Tactic:</td> <td>{Special}</td>
			  </tr>
		  </InfoTab>
		  <InfoTab top={TopMargin} left={480} width="300" tabcolor={tabterrain} >
			  <tr>
				  <th>Terrain Info:</th> <th>Values:</th>
			  </tr>
			  <tr>
				  <td>Position (X;Y):</td> <td>({TX},{TY})</td>
			  </tr>
			  <tr>
				  <td>Type:</td> <td>{TypeT}</td>
			  </tr>
			  <tr>
				  <td>Terrain's Bonus Defense:</td> <td>{D}</td>
			  </tr>
		  </InfoTab>
		  <InfoTab top={TopMargin + 480} left={0} width="200" height="450" tabcolor={tabunit}>
			  <tr>
				  <th>Alpha:</th> <th>Values:</th>
			  </tr>
			  <tr>
				  <td>Type:</td> <td>{Types[Alpha1.type.IMG]}</td>
			  </tr>
			  <tr>
				  <td>Position (X;Y):</td> <td>({(Alpha1.X) / 32},{(Alpha1.Y - TopMargin)/32})</td>
			  </tr>
			  <tr>
				  <td>HP:</td> <td>{Alpha1.HP} - <img align="center" src={Alpha1HP} /></td>
			  </tr>
			  <tr>
				  <td>Mobility:</td> <td>{Alpha1.type.MOV}</td>
			  </tr>
			  <tr>
				  <td>Soft Attack:</td> <td>{Alpha1.type.SA}</td>
			  </tr>
			  <tr>
				  <td>Soft Defense:</td> <td>{Alpha1.type.SD} + {Alpha1.BD}</td>
			  </tr>
			  <tr>
				  <td>Hard Attack:</td> <td>{Alpha1.type.HA}</td>
			  </tr>
			  <tr>
				  <td>Hard Defense:</td> <td>{Alpha1.type.HD}</td>
			  </tr>
			  <tr>
				  <td>Tactic:</td> <td>{Alpha1.type.Special}</td>
			  </tr>
		  </InfoTab>
		  <InfoTab top={TopMargin + 480} left={220} width="200" height="450" tabcolor={tabunit}>
			  <tr>
				  <th>Bravo:</th> <th>Values:</th>
			  </tr>
			  <tr>
				  <td>Type:</td> <td>{Types[Bravo1.type.IMG]}</td>
			  </tr>
			  <tr>
				  <td>Position (X;Y):</td> <td>({(Bravo1.X) / 32},{(Bravo1.Y - TopMargin)/32})</td>
			  </tr>
			  <tr>
				  <td>HP:</td> <td>{Bravo1.HP} - <img align="center" src={Bravo1HP} /></td>
			  </tr>
			  <tr>
				  <td>Mobility:</td> <td>{Bravo1.type.MOV}</td>
			  </tr>
			  <tr>
				  <td>Soft Attack:</td> <td>{Bravo1.type.SA}</td>
			  </tr>
			  <tr>
				  <td>Soft Defense:</td> <td>{Bravo1.type.SD} + {Bravo1.BD}</td>
			  </tr>
			  <tr>
				  <td>Hard Attack:</td> <td>{Bravo1.type.HA}</td>
			  </tr>
			  <tr>
				  <td>Hard Defense:</td> <td>{Bravo1.type.HD}</td>
			  </tr>
			  <tr>
				  <td>Tactic:</td> <td>{Bravo1.type.Special}</td>
			  </tr>
		  </InfoTab>
		  <InfoTab top={TopMargin + 480} left={440} width="200" height="450" tabcolor={tabunit}>
			  <tr>
				  <th>Charlie:</th> <th>Values:</th>
			  </tr>
			  <tr>
				  <td>Type:</td> <td>{Types[Charlie1.type.IMG]}</td>
			  </tr>
			  <tr>
				  <td>Position (X;Y):</td> <td>({(Charlie1.X) / 32},{(Charlie1.Y - TopMargin)/32})</td>
			  </tr>
			  <tr>
				  <td>HP:</td> <td>{Charlie1.HP} - <img align="center" src={Charlie1HP} /></td>
			  </tr>
			  <tr>
				  <td>Mobility:</td> <td>{Charlie1.type.MOV}</td>
			  </tr>
			  <tr>
				  <td>Soft Attack:</td> <td>{Charlie1.type.SA}</td>
			  </tr>
			  <tr>
				  <td>Soft Defense:</td> <td>{Charlie1.type.SD} + {Charlie1.BD}</td>
			  </tr>
			  <tr>
				  <td>Hard Attack:</td> <td>{Charlie1.type.HA}</td>
			  </tr>
			  <tr>
				  <td>Hard Defense:</td> <td>{Charlie1.type.HD}</td>
			  </tr>
			  <tr>
				  <td>Tactic:</td> <td>{Charlie1.type.Special}</td>
			  </tr>
		  </InfoTab>
		  <InfoTab top={TopMargin + 480} left={660} width="200" height="450" tabcolor={tabunit}>
			  <tr>
				  <th>Delta:</th> <th>Values:</th>
			  </tr>
			  <tr>
				  <td>Type:</td> <td>{Types[Delta1.type.IMG]}</td>
			  </tr>
			  <tr>
				  <td>Position (X;Y):</td> <td>({(Delta1.X) / 32},{(Delta1.Y - TopMargin)/32})</td>
			  </tr>
			  <tr>
				  <td>HP:</td> <td>{Delta1.HP} - <img align="center" src={Delta1HP} /></td>
			  </tr>
			  <tr>
				  <td>Mobility:</td> <td>{Delta1.type.MOV}</td>
			  </tr>
			  <tr>
				  <td>Soft Attack:</td> <td>{Delta1.type.SA}</td>
			  </tr>
			  <tr>
				  <td>Soft Defense:</td> <td>{Delta1.type.SD} + {Delta1.BD}</td>
			  </tr>
			  <tr>
				  <td>Hard Attack:</td> <td>{Delta1.type.HA}</td>
			  </tr>
			  <tr>
				  <td>Hard Defense:</td> <td>{Delta1.type.HD}</td>
			  </tr>
			  <tr>
				  <td>Tactic:</td> <td>{Delta1.type.Special}</td>
			  </tr>
		  </InfoTab>
		</Div>
  );
}

export default War_Room;

const Div = styled.div`
	top: ${(props) => props.top}px;
  display: flex;
  width: 100%;
  justify-content: center;
  & span {
	  color: white;
	  font-size: 24px;
	  position: absolute;
	  
`;

const Container = styled.div`
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
	background-color: ${(props) => props.col};
	color: ${(props) => props.col2};
`;

const Selector = styled.img`
	position: absolute;
	height: ${(props) => props.size}px;
	width: ${(props) => props.size}px;
	top: ${(props) => props.top}px;
	left: ${(props) => props.left}px;
`;

const StrategicMap = styled.canvas`
	position: absolute;
	top: ${(props) => props.top}px;
	left: ${(props) => props.left}px;
`;

const Unit = styled.img`
	position: absolute;
	top: ${(props) => props.top}px;
	left: ${(props) => props.left}px;
	-webkit-transform: ${(props) => props.Direct};
`;


const InfoTab = styled.table`
	position: absolute;
	top: ${(props) => props.top}px;
	left: ${(props) => props.left}px;
	background-color: lightgray;
	& th {
	  border: 2px solid;
	  background-color: ${(props) => props.tabcolor};
	  color: white;
	  border-color: black;
	  }
	& td {
		border: 2px solid;
	}
`;