import React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Peer } from "peerjs";

const peer = new Peer();
let conn = null;
let identity = null;
let otherPeer = null;
//receive ID
peer.on("open", function (id) {
	identity = id;
});
let Enemy = null;
let ally = false;
let hostile = false;
let coin = 0;

const Briefing_Room = () => {

	//prepare data
	const [Faction, setFaction] = useState('Blue');
	const [Company, setcompo] = useState("Inf");
	const [alpha, setalpha] = useState("l_inf");
	const [bravo, setbravo] = useState("l_inf");
	const [charlie, setcharlie] = useState("l_inf");
	const [delta, setdelta] = useState("l_inf");
	const [Flag, setFlag] = useState('https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg');
	const [Comp, setCompo] = useState("Nato_Symbol/Units/Inf.png");
	const [Alpha2, setAlpha] = useState("/Blue/Blue_L_Inf_Idle.gif");
	const [Bravo2, setBravo] = useState("/Blue/Blue_L_Inf_Idle.gif");
	const [Charlie2, setCharlie] = useState("/Blue/Blue_L_Inf_Idle.gif");
	const [Delta2, setDelta] = useState("/Blue/Blue_L_Inf_Idle.gif");
	const [ID, setID] = useState(identity);
	const [enemy, setEnemy] = useState(0);
	const [Status, setStatus] = useState(null);
	const [mapfact, setMap] = useState(Math.floor(Math.random() * 3));
	let faction = 'Blue';

	//retrie ID from servers
	useEffect(() => {
		peer.on("open", function (id) {
			identity = id;
			setID(id);
		});
	}, []);

	//this will prepare the unit to send and prevent imagine undefined
	function chooseUnit(unit) {
		let sol = null;
		switch (unit) {
			case 'L_Inf':
				sol = 'l_inf';
				break;
			case 'H_Inf':
				sol = 'h_inf';
				break;
			case 'IFV':
				sol = 'ifv';
				break;
			case 'MBT':
				sol = 'mbt';
				break;
			case 'Heli':
				sol = 'heli';
				break;
		}
		return sol;
	};

	//change faction
	const flagChange = (event) => {
		event.preventDefault();
		const Alpha = document.getElementById('Alpha1');
		const Bravo = document.getElementById('Bravo1');
		const Charlie = document.getElementById('Charlie1');
		const Delta = document.getElementById('Delta1');
		faction = event.target.value;
		setFaction(event.target.value);
		setAlpha('/' + faction + '/' + faction + '_' + Alpha.value + '_Idle.gif');
		setBravo('/' + faction + '/' + faction + '_' + Bravo.value + '_Idle.gif');
		setCharlie('/' + faction + '/' + faction + '_' + Charlie.value + '_Idle.gif');
		setDelta('/' + faction + '/' + faction + '_' + Delta.value + '_Idle.gif');
		switch (faction) {
			case 'Blue':
				setFlag('https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg');
				break;
			case 'Yellow':
				setFlag('/Nato_Symbol/' + faction + '.png');
				break;
			case 'Red':
				setFlag('https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg');
				break;
			case 'Green':
				setFlag('https://upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg');
				break;
		}
	};

	//change company symbol
	const CompanyChange = function (event) {
		setcompo(event.target.value);
		setCompo('Nato_Symbol/Units/' + event.target.value + '.png');
	};

	//change first squad
	const AlphaChange = function (event) {
		let sel = chooseUnit(event.target.value);
		setalpha(sel);
		setAlpha('/' + Faction + '/' + Faction + '_' + event.target.value + '_Idle.gif');
	};

	//change second squad
	const BravoChange = function (event) {
		let sel = chooseUnit(event.target.value);
		setbravo(sel);
		setBravo('/' + Faction + '/' + Faction + '_' + event.target.value + '_Idle.gif');
	};

	//change third squad
	const CharlieChange = function (event) {
		let sel = chooseUnit(event.target.value);
		setcharlie(sel);
		setCharlie('/' + Faction + '/' + Faction + '_' + event.target.value + '_Idle.gif');
	};

	//change fourth squad
	const DeltaChange = function (event) {
		let sel = chooseUnit(event.target.value);
		setdelta(sel);
		setDelta('/' + Faction + '/' + Faction + '_' + event.target.value + '_Idle.gif');
	};

	//go to the War Room
	const navigate = useNavigate();
	const openWar = () => {
		if (ally != false && hostile != false) {
			navigate("/War_Room", {
				state: {
					Mapfactor: mapfact,
					faction: Faction,
					company: Company,
					Alpha: alpha,
					Bravo: bravo,
					Charlie: charlie,
					Delta: delta,
					Coin: coin,
					ID: peer.id,
					Enemy: enemy
				}
			});
		}
	};

	//check if you have all your data
	const checkInfo = function () {
		if (ally != false && hostile != false) {
			setStatus('ready to play');
		}
	}

	// send data
	const connection = function () {
		Enemy = {
			Mapfactor: mapfact,
			faction: Faction,
			company: Company,
			Alpha: alpha,
			Bravo: bravo,
			Charlie: charlie,
			Delta: delta,
			Coin: coin,
			ID: peer.id
		};
		let otherpeer = document.getElementById("otherPeer").value;
		conn = peer.connect(otherpeer);
		conn.on('open', function () {
			conn.send(Enemy);
		});
		setStatus('Info sent');
		ally = true;
		checkInfo();
	};

	//receive data
	peer.on("connection", function (conn) {
		conn.on("data", function (data) {
			if (enemy == 0) {
				setMap(data.Mapfactor);
				setEnemy(data);
				setStatus('Info received');
				if (coin == data.Coin) {
					coin = 1;
				}
				hostile = true;
				checkInfo();
			}
		});
	});

	//return to the barracks
	const toBarracks = () => {
		navigate("/",);
	};

  return (
	  <div align='center'	  >
		  <button onClick={toBarracks} id='Barracks'>return to the Barracks</button>
		  <br />
		  <p><strong>your ID is: <br />{ID}</strong></p>
		  <img src={Flag} width="300px" height="200px" id="flag" />
		  <br />
		  <select name="Faction" id="faction" onChange={flagChange}>
		  <option value="Blue">Europe</option>
		  <option value="Yellow">United States</option>
		  <option value="Green">Russia</option>
		  <option value="Red">China</option>
		</select>
		  <br />
		  <img src={Comp} width='200px' height="200px" id="company" />
		  <br />
		  <select name="Company" id="company" onChange={CompanyChange}>
		  <option value="Inf">Infantry</option>
		  <option value="Air">Airborne Infantry</option>
		  <option value="Motor">Motorized Infantry</option>
		  <option value="Mech">Mechanized Infantry</option>
		  <option value="Arm">Armored</option>
		  <option value="Art">Artillery</option>
		  <option value="Eng">Engineer</option>
		  <option value="Rec">Recon</option>
		  <option value="SOF">Special Operation Forces</option>
		</select>
		<br />
		<br />
		<img src="Nato_Symbol//Units/Combined Arms.png" width="150px" height="150px" id="Platoon" />
		  <br />
		<img src={Alpha2} width="100px" height="100px" id="Alpha" />
		  <select name="Alpha" id="Alpha1" onChange={AlphaChange}>
			  <option value="L_Inf">Rifle Squad</option>
			  <option value="H_Inf">Weapons Squad</option>
			  <option value="IFV">Infantry Fighting vehicle</option>
			  <option value="MBT">Main Battle Tank</option>
			  <option value="Heli">Helicopter</option>
		</select>
		  <img src={Bravo2} width="100px" height="100px" id="Bravo" />
		  <select name="Bravo" id="Bravo1" onChange={BravoChange}>
			  <option value="L_Inf">Rifle Squad</option>
			  <option value="H_Inf">Weapons Squad</option>
			  <option value="IFV">Infantry Fighting vehicle</option>
			  <option value="MBT">Main Battle Tank</option>
			  <option value="Heli">Helicopter</option>
		</select>
		<br />
		  <img src={Charlie2} width="100px" height="100px" id="Charlie" />
		  <select name="Charlie" id="Charlie1" onChange={CharlieChange}>
			  <option value="L_Inf">Rifle Squad</option>
			  <option value="H_Inf">Weapons Squad</option>
			  <option value="IFV">Infantry Fighting vehicle</option>
			  <option value="MBT">Main Battle Tank</option>
			  <option value="Heli">Helicopter</option>
		</select>
		  <img src={Delta2} width="100px" height="100px" id="Delta" />
		  <select name="Delta" id="Delta1" onChange={DeltaChange}>
			  <option value="L_Inf">Rifle Squad</option>
			  <option value="H_Inf">Weapons Squad</option>
			  <option value="IFV">Infantry Fighting vehicle</option>
			  <option value="MBT">Main Battle Tank</option>
			  <option value="Heli">Helicopter</option>
		  </select>
		  <br />
		  <br />
		  <input type="text" id="otherPeer" />
		  <button type="button" id="connect" onClick={connection}>Connect</button>
		  <p id="status">{Status}</p>
		  <button onClick={openWar} id='warRoom'>To the War Room</button>
	  </div>

  );
};

export default Briefing_Room;