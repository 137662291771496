const HotelMap = [{
    level1: [12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
        12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12],
    level2: [0, 0, 0, 0, 0, 0, 0, 107, 0, 0, 0, 68, 69, 69, 69,
        0, 0, 0, 0, 0, 0, 132, 107, 132, 0, 0, 78, 79, 69, 69,
        0, 0, 0, 0, 0, 132, 132, 107, 132, 0, 0, 0, 0, 68, 69,
        0, 0, 0, 98, 89, 89, 89, 109, 89, 89, 89, 100, 0, 78, 79,
        0, 0, 0, 107, 132, 132, 132, 107, 132, 132, 141, 107, 0, 0, 0,
        0, 0, 132, 107, 132, 132, 144, 107, 132, 132, 141, 107, 0, 0, 0,
        0, 132, 132, 107, 132, 132, 132, 107, 144, 132, 141, 107, 132, 132, 0,
        89, 89, 89, 109, 89, 89, 89, 109, 89, 89, 89, 109, 89, 89, 89,
        0, 0, 132, 107, 141, 132, 132, 107, 132, 144, 132, 107, 132, 132, 0,
        0, 0, 0, 107, 141, 132, 132, 107, 132, 132, 132, 107, 132, 0, 0,
        59, 60, 0, 107, 141, 132, 144, 107, 132, 132, 132, 107, 0, 0, 0,
        69, 70, 0, 118, 89, 89, 89, 109, 89, 89, 89, 120, 0, 0, 0,
        69, 70, 0, 0, 0, 0, 132, 107, 132, 132, 0, 0, 0, 0, 0,
        69, 69, 59, 59, 60, 0, 0, 107, 132, 0, 0, 0, 0, 0, 0,
        69, 69, 69, 69, 70, 0, 0, 107, 0, 0, 0, 0, 0, 0, 0],
    level3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 134, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 134, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 134, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 134, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
}];

export default HotelMap;