import React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import EchoMap from './EchoMap.js';
import GolfMap from './GolfMap.js';
import HotelMap from './HotelMap.js';


const L_Inf = { MOV: 2, HA: 3, SA: 5, HD: 3, SD: 2, Special: "Entrenchment", IMG: "L_Inf" };
const H_Inf = { MOV: 2, HA: 5, SA: 3, HD: 3, SD: 2, Special: "Entrenchment", IMG: "H_Inf" };
const IFV = { MOV: 6, HA: 3, SA: 5, HD: 2, SD: 4, Special: "Repair", IMG: "IFV" };
const MBT = { MOV: 4, HA: 5, SA: 4, HD: 2, SD: 4, Special: "Repair", IMG: "MBT" };
const Heli = { MOV: 6, HA: 5, SA: 5, HD: 1, SD: 1, Special: "Switch", IMG: "Heli" };

const Barracks = () => {

	//prepare data

	const [Faction, setFaction] = useState('Blue');
	const [Flag, setFlag] = useState('https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg');
	const [Alpha, setAlpha] = useState("/Blue/Blue_L_Inf_Idle.gif");
	const [Bravo, setBravo] = useState("/Blue/Blue_H_Inf_Idle.gif");
	const [Charlie, setCharlie] = useState("/Blue/Blue_IFV_Idle.gif");
	const [Delta, setDelta] = useState("/Blue/Blue_MBT_Idle.gif");
	const [Echo, setEcho] = useState("/Blue/Blue_Heli_Idle.gif");
	const [col, setcolor] = useState('Blue');


	//draw map
	useEffect(() => {
		let Maps = [EchoMap, GolfMap, HotelMap];

		const canvas = document.getElementById('1-canvas');
		const ctx = canvas.getContext('2d');

		const canvas1 = document.getElementById('2-canvas');
		const ctx1 = canvas1.getContext('2d');

		const canvas2 = document.getElementById('3-canvas');
		const ctx2 = canvas2.getContext('2d');

		let tileAtlas = new Image();
		tileAtlas.src = 'Tileset.png';
		tileAtlas.onload = drawTerrain;

		let tileAtlas1 = new Image();
		tileAtlas1.src = 'Tileset.png';
		tileAtlas1.onload = drawTerrain1;

		let tileAtlas2 = new Image();
		tileAtlas2.src = 'Tileset.png';
		tileAtlas2.onload = drawTerrain2;

		let tileSize = 24;
		let tileOutputSize = 4/3;
		let updatedTileSize = tileSize * tileOutputSize;

		let atlasCol = 10;
		let atlasRow = 151;

		let mapCols = 15;
		let mapRows = 15;

		let mapHeight = mapRows * tileSize;
		let mapWidth = mapCols * tileSize;

		function drawTerrain() {
			let mapIndex = 0;
			let sourceX = 0;
			let sourceY = 0;
			let level1Map = EchoMap[0].level1;
			let level2Map = EchoMap[0].level2;
			let level3Map = EchoMap[0].level3;
			for (let col = 0; col < mapHeight; col += tileSize) {
				for (let row = 0; row < mapWidth; row += tileSize) {
					let tileVal1 = level1Map[mapIndex];
					let tileVal2 = level2Map[mapIndex];
					let tileVal3 = level3Map[mapIndex];
					if (tileVal1 != 0) {
						tileVal1 -= 1;
						sourceY = Math.floor(tileVal1 / atlasCol) * tileSize;
						sourceX = (tileVal1 % atlasCol) * tileSize;
						ctx.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal2 != 0) {
						tileVal2 -= 1;
						sourceY = (Math.floor(tileVal2 / atlasCol) * tileSize);
						sourceX = (tileVal2 % atlasCol) * tileSize;
						ctx.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal3 != 0) {
						tileVal3 -= 1;
						sourceY = Math.floor(tileVal3 / atlasCol) * tileSize;
						sourceX = (tileVal3 % atlasCol) * tileSize;
						ctx.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					mapIndex++;
				}
			}
		}
		function drawTerrain1() {
			let mapIndex = 0;
			let sourceX = 0;
			let sourceY = 0;
			let level1Map = GolfMap[0].level1;
			let level2Map = GolfMap[0].level2;
			let level3Map = GolfMap[0].level3;
			for (let col = 0; col < mapHeight; col += tileSize) {
				for (let row = 0; row < mapWidth; row += tileSize) {
					let tileVal1 = level1Map[mapIndex];
					let tileVal2 = level2Map[mapIndex];
					let tileVal3 = level3Map[mapIndex];
					if (tileVal1 != 0) {
						tileVal1 -= 1;
						sourceY = Math.floor(tileVal1 / atlasCol) * tileSize;
						sourceX = (tileVal1 % atlasCol) * tileSize;
						ctx1.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal2 != 0) {
						tileVal2 -= 1;
						sourceY = (Math.floor(tileVal2 / atlasCol) * tileSize);
						sourceX = (tileVal2 % atlasCol) * tileSize;
						ctx1.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal3 != 0) {
						tileVal3 -= 1;
						sourceY = Math.floor(tileVal3 / atlasCol) * tileSize;
						sourceX = (tileVal3 % atlasCol) * tileSize;
						ctx1.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					mapIndex++;
				}
			}
		}
		function drawTerrain2() {
			let mapIndex = 0;
			let sourceX = 0;
			let sourceY = 0;
			let level1Map = HotelMap[0].level1;
			let level2Map = HotelMap[0].level2;
			let level3Map = HotelMap[0].level3;
			for (let col = 0; col < mapHeight; col += tileSize) {
				for (let row = 0; row < mapWidth; row += tileSize) {
					let tileVal1 = level1Map[mapIndex];
					let tileVal2 = level2Map[mapIndex];
					let tileVal3 = level3Map[mapIndex];
					if (tileVal1 != 0) {
						tileVal1 -= 1;
						sourceY = Math.floor(tileVal1 / atlasCol) * tileSize;
						sourceX = (tileVal1 % atlasCol) * tileSize;
						ctx2.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal2 != 0) {
						tileVal2 -= 1;
						sourceY = (Math.floor(tileVal2 / atlasCol) * tileSize);
						sourceX = (tileVal2 % atlasCol) * tileSize;
						ctx2.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					if (tileVal3 != 0) {
						tileVal3 -= 1;
						sourceY = Math.floor(tileVal3 / atlasCol) * tileSize;
						sourceX = (tileVal3 % atlasCol) * tileSize;
						ctx2.drawImage(tileAtlas, sourceX, sourceY, tileSize,
							tileSize, row * tileOutputSize, col * tileOutputSize,
							updatedTileSize, updatedTileSize);
					}
					mapIndex++;
				}
			}
		}
	}, []);

	//change color faction
	const flagChange = (event) => {
		event.preventDefault()
		let faction = event.target.value;
		setFaction(event.target.value);
		setFlag('/Nato_Symbol/' + faction + '.png');
		setAlpha('/' + faction + '/' + faction + "_L_Inf_Idle.gif");
		setBravo('/' + faction + '/' + faction + "_H_Inf_Idle.gif");
		setCharlie('/' + faction + '/' + faction + "_IFV_Idle.gif");
		setDelta('/' + faction + '/' + faction + "_MBT_Idle.gif");
		setEcho('/' + faction + '/' + faction + "_Heli_Idle.gif");
		switch (faction) {
			case 'Blue':
				setcolor('Darkblue');
				break;
			case 'Yellow':
				setcolor('Orange');
				setFlag('/Nato_Symbol/' + faction + '.png');
				break;
			case 'Red':
				setcolor('Darkred');
				break;
			case 'Green':
				setcolor('Darkgreen');
				break;
		}
	};

	//to the briefing room
	const navigate = useNavigate();
	const toBriefing = () => {
		navigate("/Briefing_Room",);
	};

	return (
		<div align="center">
			<div padding=" 20px 20px 20px 20px">
				<h3>Whenever ready click the Button Below</h3>
				<button onClick={toBriefing} id='BriefingRoom'>To the Briefing Room</button>
				<h1 align="center">-- How to Play --</h1>
				<Container col="Black" col2="white">
				<p>
					<h3>In the Briefing Room:</h3>
					At the <strong>TOP</strong> of your window you can find your ID,
					<br />you need to send it to your Friend for playing,
					<br />remember to send your ID after you choose the composition of your Platoon
					<br />
					<h4>Example:</h4>
					<img src='ID.png' border="2px solid" />
					<br />
					<br /><strong>you can choose:</strong>
					<br />- The <strong>Faction</strong> which will change the estetic of your Platoon,
					<br />- The <strong>Company</strong> which will only change the symbol,
					<br /> - The <strong >Composition</strong > of your Platoon.
					<br />
					<br />Then you can Write your Friend ID in the Text Box and Click the "Connect" Button to send him the Information about your Platoon
					<br />When you send your data or receive the Data from your Friend the Writing below the textbox will change
					<br />When the Writing will show "Ready to Play" you can click the "War Room" Button and Start to Play
					<br />
					<br /><strong>Note</strong>: if you change something after you send the the Info just re send it before your friend go to the War Room
				</p>
				<h3>Example:</h3>
				<img src='Options.png' border="2px solid" />
				<br/>
				<img src='Info.png' border="2px solid" />
				<p>
					<br />
					<h3>In the War Room:</h3>
					<br />The Goal of the Game is to Destroy every Enemy Unit.
					<br />
					<br /><h4>Controls:</h4>
					<br /><img src="mov_keys.png" width="150" height="100" /> Use The <strong>Arrows</strong> keys to move the cursor
					<br />
					<br /><img src="Space-Key.png" width="100" height="50" /> Use The <strong>Space Bar</strong> on a Unit to make it Move
					<br />
					<br /><img src="Enter-Key.png" width="100" height="50" /> Use The <strong>Enter Key</strong> on a Unit to Make it Do an Action
					<br />
					<br />- <strong>Tactic</strong>, press the Enter key again on the same Unit
					<br />- <strong>Attack</strong>, press the Enter key again on a near by Enemy Unit
					<br />- <strong>Cancel</strong>, press the Enter key again on an Empty Tile
					<br />When you have Finish your Turn Click on "End Turn" to Send the Data To your Friend And Wait Until he End his Turn
				</p>
				<h3>Credits:</h3>
				<p>Idea, Code and Realization by Marte
					<br />Sprites By Aleksandr Makarov
					<br />
					<a href="https://iknowkingrabbit.itch.io/pangea-wars">Where to find the Sprites</a>
				</p>
				</Container>
			</div>
			<h1 align='center'>-- Maps: --</h1>
			<Container col="darkgreen">
			<Container height="640" width="1540" align='center' col2="white">
				<Tab side="left">
					<h2>Lake:</h2>
					<canvas id="1-canvas" width="480" height="480" />
				</Tab>
					<Tab side="left">
					<h2>Mountain Border:</h2>
					<canvas id="2-canvas" width="480" height="480" />
				</Tab>
				<Tab side="left">
					<h2>City:</h2>
					<canvas id="3-canvas" width="480" height="480" />
				</Tab>
				</Container>
			</Container>
			<br />
			<div align='center' padding=" 20px 20px 20px 20px">
				<h1 >-- Companies --</h1>
				<p><strong>This feature is only aesthetic for now.</strong></p>
				<Container col="darkred">
					<Container height="650" width="1000" align='center' col2="white">
						<Tab side="left">
							<img src="Nato_Symbol/Units/Inf.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Inf.png" width='150px' height="150px" />
							<p><strong>Infantry</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/Motor.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Motor.png" width='150px' height="150px" />
							<p><strong>Motorized Infantry</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/Mech.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Mech.png" width='150px' height="150px" />
							<p><strong>Mechanized Infantry</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/Air.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Air.png" width='150px' height="150px" />
							<p><strong>Airborne Infantry</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/Rec.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Rec.png" width='150px' height="150px" />
							<p><strong>Reconnaissance</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/Arm.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Arm.png" width='150px' height="150px" />
							<p><strong>Armored</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/Art.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Art.png" width='150px' height="150px" />
							<p><strong>Artillery</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/Eng.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/Eng.png" width='150px' height="150px" />
							<p><strong>Engineer</strong></p>
						</Tab>
						<Tab side="left">
							<img src="Nato_Symbol/Units/SOF.png" width='150px' height="150px" />
							<img src="Nato_Symbol/Enemy/SOF.png" width='150px' height="150px" />
							<p><strong>Special Operations Forces</strong></p>
						</Tab>
					</Container>
				</Container>
			</div>
			<br />
			<br />
			<div align='center' padding=" 20px 20px 20px 20px">
			<h1>-- Factions and Units --</h1>
				<p><strong>This feature is only aesthetic for now.</strong></p>
				<Container col="purple">
				<img align='center' src={Flag} width="300px" height="200px" id="flag" />
				<br />
				<select name="Faction" id="faction" onChange={flagChange}>
					<option value="Blue">Europe</option>
					<option value="Yellow">United States</option>
					<option value="Green">Russia</option>
					<option value="Red">China</option>
				</select>
				<Container align="center" width="930" height="1775" align='center' col2="white">
					<Tab side="left">
						<h2>Rifle Squad:</h2>
						<br />
						<img src={Alpha} width="96px" height="96px" id="L_inf" />
						<br />
						<InfoTab tabcolor={col}>
							<tr>
								<th color="white">Info:</th> <th color="white">Values:</th>
							</tr>
							<tr>
								<td>HP:</td> <td>8</td>
							</tr>
							<tr>
								<td>Mobility:</td> <td>{L_Inf.MOV}</td>
							</tr>
							<tr>
								<td>Soft Attack:</td> <td>{L_Inf.SA}</td>
							</tr>
							<tr>
								<td>Soft Defense:</td> <td>{L_Inf.SD}</td>
							</tr>
							<tr>
								<td>Hard Attack:</td> <td>{L_Inf.HA}</td>
							</tr>
							<tr>
								<td>Hard Defense:</td> <td>{L_Inf.HD}</td>
							</tr>
							<tr>
								<td>Tactic:</td> <td>{L_Inf.Special}</td>
							</tr>
						</InfoTab>
						<br />
						<p>An 8/14 elements Squad Lead by an NCO
							<br />armed with Assault Rifle, LMG and Granade launcher
							<br />Effective against Infantry, Weak against Vehicles
							<br />his Main tactics is Entrenching Himself to boost the defense</p>
					</Tab>
					<Tab side="right">
						<h2>Weapons Squad:</h2>
						<br />
						<img src={Bravo} width="96px" height="96px" id="H_inf" />
						<br />
						<InfoTab tabcolor={col}>
							<tr>
								<th color="white">Info:</th> <th color="white">Values:</th>
							</tr>
							<tr>
								<td>HP:</td> <td>8</td>
							</tr>
							<tr>
								<td>Mobility:</td> <td>{H_Inf.MOV}</td>
							</tr>
							<tr>
								<td>Soft Attack:</td> <td>{H_Inf.SA}</td>
							</tr>
							<tr>
								<td>Soft Defense:</td> <td>{H_Inf.SD}</td>
							</tr>
							<tr>
								<td>Hard Attack:</td> <td>{H_Inf.HA}</td>
							</tr>
							<tr>
								<td>Hard Defense:</td> <td>{H_Inf.HD}</td>
							</tr>
							<tr>
								<td>Tactic:</td> <td>{H_Inf.Special}</td>
							</tr>
						</InfoTab>
						<br />
						<p>An 8/14 elements Squad Lead by an NCO
							<br />armed with Assault Rifle, HMG and AT/AA Launchers
							<br />Effective against Vehicles, Weak against Infantry
							<br />his Main tactics is Entrenching Himself to boost the defense</p>
					</Tab>
					<Tab side="left">
						<h2>Infantry Fighting Vehicle:</h2>
						<br />
						<img src={Charlie} width="96px" height="96px" id="Ifv" />
						<br />
						<InfoTab tabcolor={col}>
							<tr>
								<th color="white">Info:</th> <th color="white">Values:</th>
							</tr>
							<tr>
								<td>HP:</td> <td>8</td>
							</tr>
							<tr>
								<td>Mobility:</td> <td>{IFV.MOV}</td>
							</tr>
							<tr>
								<td>Soft Attack:</td> <td>{IFV.SA}</td>
							</tr>
							<tr>
								<td>Soft Defense:</td> <td>{IFV.SD}</td>
							</tr>
							<tr>
								<td>Hard Attack:</td> <td>{IFV.HA}</td>
							</tr>
							<tr>
								<td>Hard Defense:</td> <td>{IFV.HD}</td>
							</tr>
							<tr>
								<td>Tactic:</td> <td>{IFV.Special}</td>
							</tr>
						</InfoTab>
						<br />
						<p>A Vechles with 3/4 elements Crew,
							<br />armed with Autocannon, Machine Gun and AGL
							<br />An armor resistent to small Weapons
							<br />Effective against Infantry, Weak against Vehicles
							<br />his Main tactics is Emergency Repair for regain Vitality</p>
					</Tab>
					<Tab side="right">
						<h2>Main Battle Tank:</h2>
						<br />
						<img src={Delta} width="96px" height="96px" id="Mbt" />
						<br />
						<InfoTab tabcolor={col}>
							<tr>
								<th color="white">Info:</th> <th color="white">Values:</th>
							</tr>
							<tr>
								<td>HP:</td> <td>8</td>
							</tr>
							<tr>
								<td>Mobility:</td> <td>{MBT.MOV}</td>
							</tr>
							<tr>
								<td>Soft Attack:</td> <td>{MBT.SA}</td>
							</tr>
							<tr>
								<td>Soft Defense:</td> <td>{MBT.SD}</td>
							</tr>
							<tr>
								<td>Hard Attack:</td> <td>{MBT.HA}</td>
							</tr>
							<tr>
								<td>Hard Defense:</td> <td>{MBT.HD}</td>
							</tr>
							<tr>
								<td>Tactic:</td> <td>{MBT.Special}</td>
							</tr>
						</InfoTab>
						<br />
						<p>A Vechles with 3/4 elements Crew,
							<br />armed with Smooth board Cannon, Machine Gun
							<br />An armor resistent to Heavy Weapons
							<br />Effective against Vehicles, Weak against Infantry
							<br />his Main tactics is Emergency Repair for regain Vitality</p>
					</Tab>
					<Tab side="left">
						<h2>Attack Helicopter:</h2>
						<br />
						<img src={Echo} width="96px" height="96px" id="HELI" />
						<br />
						<InfoTab tabcolor={col}>
							<tr>
								<th color="white">Info:</th> <th color="white">Values:</th>
							</tr>
							<tr>
								<td>HP:</td> <td>8</td>
							</tr>
							<tr>
								<td>Mobility:</td> <td>{Heli.MOV}</td>
							</tr>
							<tr>
								<td>Soft Attack:</td> <td>{Heli.SA}</td>
							</tr>
							<tr>
								<td>Soft Defense:</td> <td>{Heli.SD}</td>
							</tr>
							<tr>
								<td>Hard Attack:</td> <td>{Heli.HA}</td>
							</tr>
							<tr>
								<td>Hard Defense:</td> <td>{Heli.HD}</td>
							</tr>
							<tr>
								<td>Tactic:</td> <td>{Heli.Special}</td>
							</tr>
						</InfoTab>
						<br />
						<p>An Aircraft with 2/4 Crewman,
							<br />armed with Machine Gun, Rockets;
							<br />a weak armor resistent only to Shrapnel
							<br />Effective against Infantry and Vehicles,
							<br />Weak against Infantry and Vehicles
							<br />his Main tactics is Switching between High and Low Altitude
							<br />In High Alt. the Unit can not Attack or get Attacked
							<br />In Low Alt. the Unit can Attack and get Attacked
						</p>
					</Tab>
					</Container>
				</Container>
			</div>
		</div>

	);
};

export default Barracks;

const InfoTab = styled.table`
	background-color: lightgray;
	& th {
	  border: 2px solid;
	  background-color: ${(props) => props.tabcolor};
	  color: white;
	  border-color: black;
	  }
	& td {
		border: 2px solid;
		color: black;
	}
`;

const Container = styled.div`
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
	padding: 20px 20px 20px 20px;
	background-color: ${(props) => props.col};
	color: ${(props) => props.col2};
`;

const Tab = styled.div`
	float: ${(props) => props.side};
    padding: 10px 10px 10px 10px;
`;